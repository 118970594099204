
import { defineComponent, ref } from 'vue';
import Questions from '@/components/Questions.vue'
import Question from '../models/Question';
import Section from '../models/Section';
import Questionnaire from '../models/Questionnaire'; import Answer from '../models/Answer';
import { useRouter } from 'vue-router';
import QuestionnaireSession from '@/models/QuestionnaireSession';
import { updateSession } from '@/firebase';

export default defineComponent({
  name: 'Home',
  components: {
   Questions
  },
  setup ()  
  {
    const warning = ref<string>();
    const questions = ref<Question[]>([]);
    const nextButton = ref<HTMLButtonElement>();
    const showBack = ref<boolean>(false);

    if (localStorage.questionnaire == null)
    {
      useRouter().push({ name: 'Home' });
      return;
    }

    let savedQuestionnaire = JSON.parse(localStorage.questionnaire);
    const questionnaire = ref<Questionnaire>(new Questionnaire(savedQuestionnaire));

    let savedSession = JSON.parse(localStorage.session);
    const session = ref<QuestionnaireSession>(new QuestionnaireSession(savedSession));

    const currentSection = ref<Section>(questionnaire.value.sections[questionnaire.value.current_section_idx]);
    const currentQuestion = ref<Question>(currentSection.value.questions[questionnaire.value.current_question_idx]);

    let arr = [];

    if (questionnaire.value.current_section_idx == 0 && questionnaire.value.current_question_idx == 0)
      showBack.value = false;
    else
      showBack.value = true;

    const nextClicked = function () {

      if (questionnaire.value.status == "")
      {
        questionnaire.value.status = "started";
        return;
      }
      
      if (currentQuestion.value !== undefined && currentQuestion.value.selectedAnswer !== undefined)
      {
        getNextQuestion()
      }
      else
      {
        warning.value = "Please select an answer before going to the next question";
      }
    }

    const backClicked = function () {

      getPreviousQuestion();
    }

    const getPreviousQuestion = () =>
    {      
      let sectionQuestions = currentSection.value.questions;

      if (questionnaire.value.current_question_idx - 1 < 0)
      {
         if (questionnaire.value.current_section_idx - 1 < 0)
         {          
           //this is the very beginning do nothing
           console.log('beginning');
           showBack.value = false;
         }  
         else
         {           
           questionnaire.value.current_question_idx = questionnaire.value.sections[questionnaire.value.current_section_idx].questions.length - 1;
           questionnaire.value.current_section_idx--;

           currentSection.value = questionnaire.value.sections[questionnaire.value.current_section_idx];
           sectionQuestions = currentSection.value.questions;
         }
      }
      else
        questionnaire.value.current_question_idx--;

      currentQuestion.value = sectionQuestions[questionnaire.value.current_question_idx];

      if (questionnaire.value.current_section_idx == 0 && questionnaire.value.current_question_idx == 0)
        showBack.value = false;
      else
        showBack.value = true;

      questionnaire.value.sections[questionnaire.value.current_section_idx].questions[questionnaire.value.current_question_idx] = currentQuestion.value;
      localStorage.questionnaire = JSON.stringify(questionnaire.value);
    };

    const getNextQuestion = () =>
    {      
      let sectionQuestions = currentSection.value.questions;

      if (questionnaire.value.current_question_idx + 1 >= sectionQuestions.length)
      {
         if (questionnaire.value.current_section_idx + 1 >= questionnaire.value.sections.length)
         {          
           questionnaire.value.status = 'complete';          
           questionnaire.value.valueGroups = calculateTotals();
           localStorage.questionnaire = JSON.stringify(questionnaire.value);        

           updateSession(session.value.id, JSON.stringify(questionnaire.value.valueGroups), JSON.stringify(questionnaire.value)).then( (data) => {
              
                //clear session date
                localStorage.removeItem('session');
                localStorage.removeItem('questionnaire');
           });
    
           return false;
         }  
         else
         {           
           questionnaire.value.current_question_idx = 0;
           questionnaire.value.current_section_idx++;

           currentSection.value = questionnaire.value.sections[questionnaire.value.current_section_idx];
           sectionQuestions = currentSection.value.questions;
         }
      }
      else
        questionnaire.value.current_question_idx++;

      currentQuestion.value = sectionQuestions[questionnaire.value.current_question_idx];

      showBack.value = true;

      questionnaire.value.sections[questionnaire.value.current_section_idx].questions[questionnaire.value.current_question_idx] = currentQuestion.value;
      localStorage.questionnaire = JSON.stringify(questionnaire.value);
    };

    const answerSelected = function (answer: Answer) {
      
      if (currentQuestion.value !== undefined)
      {
          currentQuestion.value.selectedAnswer = answer;
          currentSection.value.total += answer.value;
      }
    }

    const calculateTotals = () =>
    {
      let sections = savedQuestionnaire.sections;

      let value_groups: { [group: string]: number} = {};

      sections.forEach((section: Section) => 
      {
        section.questions.forEach((question: Question) => 
        {
          if (question.selectedAnswer)
          {
            if (question.value_group in value_groups)
                value_groups[question.value_group] += question.selectedAnswer.value;
            else
                value_groups[question.value_group] = question.selectedAnswer.value;            
          }

          localStorage.valueGroups = JSON.stringify(value_groups); 
        });
      });

      return value_groups;
    }

    calculateTotals();

    return { currentSection, currentQuestion, warning, questionnaire, session, showBack, nextClicked, backClicked, answerSelected, calculateTotals }
  }
});
