
import Answer from '@/models/Answer';
import { defineComponent, PropType, ref, toRef } from 'vue';
import Question from '../models/Question';

export default defineComponent({
  name: 'Questions',
  props: {
    question: {
        type: Object as PropType<Question>,
        required: true
    }
  },
  emits: [
      'answerSelected'
  ],
  setup (props, context) {

      const question = toRef(props, 'question');

      const questionAnswered =  (answer: Answer) => {
        question.value.selectedAnswer = answer;
        context.emit('answerSelected', question.value.selectedAnswer);
      }

      return { questionAnswered }
  }
});
